import DiscordIcon from "virtual:icons/ic/baseline-discord";
import InstagramIcon from "virtual:icons/mdi/instagram";
import { SignedIn } from "@clerk/remix";
import { Link, type LinkProps } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { $path } from "remix-routes";

import { cx } from "#app/utils/cva.ts";

export function Footer() {
	const { t } = useTranslation("common");
	return (
		<footer className="mt-auto grid grid-cols-2 gap-y-8 p-4 lg:grid-cols-5 md:grid-cols-3 lg:gap-x-8 md:p-8">
			{/* logo and description */}
			<section className="col-span-2 lg:col-span-2 md:col-span-3">
				<div className="relative w-52">
					<img alt="timple.ai" className="size-full object-cover" src="/logo.svg" />
				</div>
				<p className="mt-2 max-w-sm text-muted-foreground text-sm dark:text-zinc-400">
					Experience your dreams with adult-themed AI roleplay. Dive into explicit AI conversation with the person of
					your dreams. Begin your journey with AI sexting and explicit chat bots now!
					<br />
					<br />
					Customer Support : support@timple.ai | (888) 900-9501
					<br />
					<span>NextGen AI Holdings LLC</span>
					<br />
					<span>P.O. Box 819</span>
					<br />
					<span>Wittmann AZ 85361</span>
				</p>
				<p className="mt-4 max-w-sm text-muted-foreground text-sm dark:text-zinc-400">
					© {new Date().getFullYear()} Timple.AI. {t("allRightsReserved")}
				</p>
			</section>

			{/* features */}
			<section className="col-span-1">
				<p className="mb-2 font-semibold">{t("chatAI18")}</p>
				<ul className="flex flex-col gap-1">
					<li>
						<Link className="line-clamp-1 text-sm" to="/">
							{t("chatAI18")}
						</Link>
					</li>
					<li>
						<Link className="line-clamp-1 text-sm" to="/faq">
							FAQ
						</Link>
					</li>
				</ul>
			</section>

			{/* resources */}
			<section className="col-span-1">
				<p className="mb-2 font-semibold">{t("features")}</p>
				<ul className="flex flex-col gap-1">
					<li>
						<Link className="line-clamp-1 text-sm" to="/legal/community-guidelines">
							{t("communityGuidelines")}
						</Link>
					</li>
					<li>
						<Link className="line-clamp-1 text-sm" to="/legal/">
							{t("legal")}
						</Link>
					</li>
					<SignedIn>
						<li>
							<Link className="line-clamp-1 text-sm" to={$path("/reports")}>
								Reports
							</Link>
						</li>
					</SignedIn>
				</ul>
			</section>

			{/* community */}
			<section className="col-span-1">
				<p className="font-semibold">{t("joinTheCommunity")}</p>

				<ul className="flex">
					<li>
						<SocialLink to="https://discord.gg/r7kJ5u28" target="_blank" rel="noreferrer noopener">
							<DiscordIcon className="size-5 text-muted-foreground dark:group-hover:text-zinc-300 dark:text-zinc-400" />
						</SocialLink>
					</li>
					<li>
						<SocialLink to="https://instagram.com/timple.ai" target="_blank" rel="noreferrer noopener">
							<InstagramIcon className="size-5 text-muted-foreground dark:group-hover:text-zinc-300 dark:text-zinc-400" />
						</SocialLink>
					</li>
				</ul>
			</section>
		</footer>
	);
}

function SocialLink({ className, ...props }: LinkProps) {
	return (
		<Link
			{...props}
			className={cx(
				"inline-flex size-10 items-center justify-center rounded-lg bg-transparent font-medium text-sm ring-offset-background transition-colors disabled:pointer-events-none dark:hover:bg-zinc-700/50 hover:bg-slate-100 hover:text-accent-foreground disabled:opacity-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
				className,
			)}
		/>
	);
}
